/* You can add global styles to this file, and also import other style files */
*,
*:before,
*:after {
    box-sizing: border-box !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.content-area {
    padding: 0 !important;
}
.pb04 {
    padding-bottom: 0.4rem !important;
}
.pl-1rem {
    padding-left: 1rem !important;
}
.pl05 {
    padding-left: 0.5rem !important;
}
h4,
h5 {
    margin-top: 0 !important;
}

.close-icon {
    float: right !important;
    cursor: pointer !important;
}
.mt05 {
    margin-top: 0.5rem;
}
.mt04 {
    margin-top: 0.4rem;
}
.cursor-pointer {
    cursor: pointer;
}
.clr-form-control {
    margin-top: 0rem !important;
}

.fw900 {
    font-weight: 900;
}
.fs06 {
    font-size: 0.6rem;
}
.fs05 {
    font-size: 0.5rem;
}
select {
    width: 7rem !important;
}
.control-label {
    font-size: 12px !important;
    color: #888 !important;
}
.control-content {
    font-size: 12px !important;
    font-weight: 500 !important;
}
.clr-control-label {
    font-weight: unset !important;
    line-height: unset !important;
}
.text-align-end {
    text-align: end;
}
.text-align-right {
    text-align: right;
}
.text-align-center {
    text-align: center;
}
.text-align-left {
    text-align: left;
}
.divider {
    border-bottom: 0.09rem solid #f0f0f0;
    width: 100%;
}
//scss for custom Accordion
.accordion {
    background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    font-weight: 900;
}

.accordion:hover {
    background-color: #eee;
}

.accordion:before {
    content: "\002B";
    color: #25acea;
    // font-weight: bold;
    float: left;
    margin-left: 5px;
    padding-right: 10px;
    font-size: 20px;
}

.active:before {
    content: "\2212";
}
.tab-content.active:before,
button.nav-link:before {
    content: "" !important;
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.signpost-wrap {
    border: unset !important;
    box-shadow: 1px 1px 6px #888;
}
#management:fullscreen {
    .signpost-wrap {
        top: 100px;
    }
}
.signpost-content {
    width: 21%;
}

.display-flex {
    display: flex;
    justify-content: space-between;
}

.flex-row {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.flex-col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}
.clr-toggle-wrapper {
    height: unset !important;
    position: unset !important;
    display: unset !important;
    margin-right: unset !important;
}
//for multi select component
////////////////////////////////////
$base-color: #0079fe;
$btn-background: #fff;
$btn-border: #ccc;
$btn-text-color: #333;
$btn-arrow: #333;

$token-background: $base-color;
$token-text-color: #fff;
$token-remove-color: #fff;

$box-shadow-color: #959595;
$list-hover-background: #f5f5f5;
$label-color: #000;
$selected-background: #e9f4ff;

.c-btn {
    background: $btn-background;
    border: 1px solid $btn-border;
    color: $btn-text-color;
}
.selected-list {
    .c-list {
        .c-token {
            background: $token-background;
            .c-label {
                color: $token-text-color;
            }
            .c-remove {
                svg {
                    fill: $token-remove-color;
                }
            }
        }
    }
    .c-angle-down,
    .c-angle-up {
        svg {
            fill: $btn-arrow;
        }
    }
}
.dropdown-list {
    //width: unset !important;
    ul {
        li:hover {
            background: $list-hover-background;
        }
    }
}
.arrow-up,
.arrow-down {
    border-bottom: 15px solid #fff;
}

.arrow-2 {
    border-bottom: 15px solid #ccc;
}
.list-area {
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 1px 5px $box-shadow-color;
}
.select-all {
    border-bottom: 1px solid #ccc;
}
.list-filter {
    border-bottom: 1px solid #ccc;
    .c-search {
        svg {
            fill: #888;
        }
    }
    .c-clear {
        svg {
            fill: #888;
        }
    }
}

.pure-checkbox {
    input[type="checkbox"]:focus + label:before,
    input[type="checkbox"]:hover + label:before {
        border-color: $base-color;
        background-color: #f2f2f2;
    }
    input[type="checkbox"] + label {
        color: $label-color;
    }
    input[type="checkbox"] + label:before {
        color: $base-color;
        border: 1px solid $base-color;
    }
    input[type="checkbox"] + label:after {
        background-color: $base-color;
    }
    input[type="checkbox"]:disabled + label:before {
        border-color: #cccccc;
    }
    input[type="checkbox"]:disabled:checked + label:before {
        background-color: #cccccc;
    }
    input[type="checkbox"] + label:after {
        border-color: #ffffff;
    }
    input[type="radio"]:checked + label:before {
        background-color: white;
    }
    input[type="checkbox"]:checked + label:before {
        background: $base-color;
    }
}
.single-select-mode .pure-checkbox {
    input[type="checkbox"]:focus + label:before,
    input[type="checkbox"]:hover + label:before {
        border-color: $base-color;
        background-color: #f2f2f2;
    }
    input[type="checkbox"] + label {
        color: $label-color;
    }
    input[type="checkbox"] + label:before {
        color: transparent !important;
        border: 0px solid $base-color;
    }
    input[type="checkbox"] + label:after {
        background-color: transparent !important;
    }
    input[type="checkbox"]:disabled + label:before {
        border-color: #cccccc;
    }
    input[type="checkbox"]:disabled:checked + label:before {
        background-color: #cccccc;
    }
    input[type="checkbox"] + label:after {
        border-color: $base-color;
    }
    input[type="radio"]:checked + label:before {
        background-color: white;
    }
    input[type="checkbox"]:checked + label:before {
        background: none !important;
    }
}
.selected-item {
    background: $selected-background;
}
.btn-iceblue {
    background: $base-color;
    border: 1px solid $btn-border;
    color: #fff;
}
input[type="checkbox"][readonly] ~ label {
    pointer-events: none;
}
.clr-accordion-inner-content {
    padding: 6px 10px !important;
}

ul.nav {
    margin-top: 10px;
    margin-left: 10px;
    width: 99%;
}
.openContent.content-left {
    margin-left: 2% !important;
    transition: 0.5s;
    .sticky {
        width: 95% !important;
    }
}
.workloadData {
    // background: #fff;
    padding: 10px;
    padding-right: 20px;
    // box-shadow: 0px 1px 2px #a5a4a4;
    // margin-left: 8px;
    // margin-right: 2px;
    .cards {
        background: #eee !important;
        border: 1px solid #e6e6e6;
        input {
            background: #eee !important;
        }
    }
}
.accordian_mangement {
    .clr-accordion-inner-content {
        height: 224px;
        overflow: auto;
    }
}
.readyNotesOutput {
    margin-top: 10px;
    text-align: center;
    label {
        text-align: right;
    }
    .clr-form-control {
        margin-top: 7px !important;
    }
}
.loaderBody {
    .modal-content {
        background: transparent;
        box-shadow: none;
        position: relative;
        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
// .active:before {
//     content: "";
// }
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
::-ms-clear {
    display: none;
}
.showWorkload {
    clr-tabs {
        .nav {
            .nav-item {
                button {
                    max-width: 110px;
                    overflow: hidden;
                }
                .dropdown-toggle {
                    width: 40px;
                }
            }
        }
    }
}
.onepod {
    .selected-list {
        .c-list {
            .c-token {
                font-size: 0.5rem !important;
            }
        }
        .c-btn {
            border: none;
            border-bottom: 1px solid #cccccc;
            border-radius: 0;
        }
    }
}
.create-poject-div {
    .clr-select-wrapper {
        width: 80%;
    }
}

.controlplane {
    .backgroundgrid {
        .clr-input-wrapper {
            max-height: 1.4rem !important;
        }
    }
}
.k8clusters-group {
    .cluster-title {
        .clr-control-label {
            margin-top: 0 !important;
        }
    }
    .nsxt-edge-panel {
        .clr-control-label {
            display: inline-block;
            margin-top: 0.25rem;
        }
    }
}
.info-text {
    color: #308ede;
    font-size: 0.5rem;
    font-weight: 500;
    margin: 0;
}
// cookie button style
button.ot-sdk-show-settings {
    position: fixed !important;
    bottom: 0px !important;
    right: 0px !important;
    z-index: 999999 !important;
    color: rgb(255, 255, 255) !important;
    border: 2px solid rgb(29, 66, 138) !important;
    padding: 6px !important;
    display: inline-block;
    margin: -8px 0px 1.2px -82px;
    background-color: rgb(29, 66, 138);
    cursor: pointer;
    border-radius: 2.75rem;
    font-size: 0.8em;
    line-height: 1.2;
    min-width: 200px;
    height: auto;
    font-weight: bold;
}
button.ot-sdk-show-settings:hover {
    color: #fff;
    background-color: #1d428a !important;
}
.nodes-table {
    .datagrid {
        overflow-x: hidden !important;
    }
}
.tanzu-settings {
    .clr-control-container.control-content {
        display: inline-block !important;
    }
}
.text-blue {
    color: #0095d3 !important;
}
